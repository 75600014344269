import { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import styles from "./styles/PoweredByCS.module.scss";
import ClickableLink from "../utilities/ClickableLink";
import Button from "../utilities/Button";
import { StoreContext } from "../../stores/StoreLoader";
import { LogoutButton } from "../admin/sidebar/items/LogoutButton";

const poweredByContainerClassName = classNames({
    [styles.poweredByContainer]: true,
});
const loginButtonClassName = classNames({
    [styles.loginButton]: true,
});

const PoweredBy = observer(props => {
    const {modalStore, userStore, interfaceStore} = useContext(StoreContext);

    function openLogin() {
        modalStore.addModal({
            type: "login",
            size: "lg",
            id: "login-modal",
        });
    }

    return (
        <div className={poweredByContainerClassName}>
            <div>
                {userStore.id ? <LogoutButton className={loginButtonClassName}>Logout</LogoutButton>
                    : <Button className={loginButtonClassName} onClick={openLogin}>Login</Button>}
            </div>
            <small>This website is powered by <span>
                <ClickableLink href={"https://www.schoolblocks.com"}>SchoolBlocks </ClickableLink>
                and
                <ClickableLink href={"https://www.schoolfeed.org"}> SchoolFeed</ClickableLink>

            </span></small>
        </div>
    )
});

export default PoweredBy;
